import React, { useEffect } from "react";
import MainLayout from "../layouts/main-layout";
import { graphql } from "gatsby";
import { useTranslation } from "react-i18next";
import { getNodes } from "../utils/get-nodes";
import AOS from "aos";
import "aos/dist/aos.css";

import {
  top,
  details,
  socials,
  text,
  image,
  imageContainer,
  title,
  logo,
  social,
  personDetails,
} from "./person.module.scss";

import LinkedinLogo from "../assets/images/svg/linkedin.svg";
import Envelope from "../assets/images/svg/envelope.svg";
import Detail from "../components/molecules/datail";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { IQueryAllResult } from "../models/query-all-result.model";
import { IPerson } from "../models/person.model";
import { person } from "../config/page-contexts";

interface IPersonProps {
  className: string;
  readonly data: {
    allStrapiPerson: IQueryAllResult<IPerson>;
  };
}

const Person: React.FC<IPersonProps> = ({ className = "", data }) => {
  const { t } = useTranslation();
  const { allStrapiPerson } = data;
  const personData = getNodes(allStrapiPerson)[0];
  console.log(allStrapiPerson, "all strapi pewson");

  useEffect(() => {
    AOS.init({ once: true });
  }, []);

  const {
    Name = "",
    Surname = "",
    Degree = "",
    Position_1 = "",
    Position_2 = "",
    Email = "",
    Linkedin = "",
    Details = "",
    img_profile = "",
  } = personData || {};

  const gatsbyImage = getImage(img_profile.localFile);

  console.log(Details, "here details");

  return (
    <MainLayout
      titleSeo={`${Name} ${Surname}`}
      descriptionSeo={`${Name} ${Surname}`}
    >
      <div className={top} data-aos="fade-left">
        <div className={imageContainer}>
          {gatsbyImage && (
            <GatsbyImage
              alt={""}
              image={gatsbyImage}
              className={image}
              objectFit="contain"
              objectPosition="bottom"
            />
          )}
        </div>
        <div className={details}>
          <h1 className={title}>
            {Name} <br /> {Surname}
          </h1>
          <p className={text}>{Degree}</p>
          <p className={text}>{Position_1}</p>
          <p className={text}>{Position_2}</p>

          <div className={socials}>
            {Linkedin && (
              <a href={Linkedin} className={social} target={"_blank"}>
                <LinkedinLogo className={logo} />
              </a>
            )}
            <a href={`mailto:${Email}`} className={social}>
              <Envelope className={logo} />
            </a>
          </div>
        </div>
      </div>
      <div className={personDetails}>
        {Details &&
          Details?.map((detail, index) => {
            const image = getImage(detail.Icon.localFile);

            return (
              <Detail
                key={index}
                image={image}
                title={detail.detail_title}
                text={detail.detail_text.data.detail_text}
              />
            );
          })}
      </div>
    </MainLayout>
  );
};

export const query = graphql`
  query ($language: String!, $slug: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allStrapiPerson(
      filter: { slug: { eq: $slug }, locale: { eq: $language } }
    ) {
      edges {
        node {
          Surname
          slug
          img_profile {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          Details {
            detail_title
            detail_text {
              data {
                detail_text
              }
            }
            Icon {
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
          }
          Sequence
          Position_2
          Position_1
          Name
          locale
          Linkedin
          Email
          Degree
          id
        }
      }
    }
  }
`;

export default Person;
