// extracted by mini-css-extract-plugin
export var details = "person-module--details--Z5Mam";
export var image = "person-module--image--KpMWI";
export var imageContainer = "person-module--image-container--3uBPs";
export var logo = "person-module--logo--t1Afv";
export var personDetails = "person-module--person-details--+is5J";
export var social = "person-module--social--kX0YS";
export var socials = "person-module--socials--9ASf-";
export var text = "person-module--text--UnnFH";
export var title = "person-module--title--R0eBP";
export var top = "person-module--top--m07gA";